
  .Main-body{
    text-align: center;
    min-height: 100vh;
    min-width: 100vh;
    width: auto;
    height: auto;
    overflow: auto;
    font-size: 50px;
    overflow-x: hidden; 
    overflow-y: auto; 
  }

::-webkit-scrollbar {
  width: 2.5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #161616;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
